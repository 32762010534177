<template>
  <div class="page-layout" :class="{ 'page-layout--hidden': !showSidebarMenu }">
    <aside v-if="website" class="page-sidebar relative flex flex-col min-h-0">

      <header class="page-sidebar__header w-full top-0 border-b border-prasset-green-300">
        <div class="page-sidebar__header__title">{{ $tuf('pages') }}</div>
        <div class="page-sidebar__header__collapse" @click="showSidebarMenu = false">&times;</div>
      </header>

      <nav v-if="website" class="page-sidebar__nav relative flex flex-1 overflow-auto z-1 w-full h-full">
        <Draggable v-model="website.pages" @input="onBeforeSort" @sort="onSortPages" class="w-full">
          <div v-for="p in website.pages" :key="p.id">
            <RouterLink :to="{ name: 'website-page', params: { page_id: p.id } }" class="page-sidebar__nav__link">
              <span class="block truncate mr-2">{{ p.name }}</span>
              <span class="flex flex-row justify-center items-center">
                <i v-if="p.options && p.options.hide_from_nav === true" class="ri-eye-off-line mr-2"></i>
                <span class="block rounded-full w-2 h-2" :class="{ 'bg-orange-600': p.status === 'IS_DRAFT', 'bg-prasset-green-400': p.status === 'IS_PUBLISHED' }" />
              </span>
            </RouterLink>
          </div>
        </Draggable>
      </nav>

      <div class="page-sidebar__bottom flex flex-col w-full border-t border-prasset-green-300 py-2">
        <ul class="flex flex-col">
          <li>
            <RouterLink :to="{ name: 'website-page-create' }" class="px-3 py-2 flex flex-row items-end leading-none transition duration-100 transition-colors text-prasset-gray-800 hover:text-prasset-green-400 focus:text-prasset-green-400">
              <i class="ri-add-circle-line mr-2" />
              <span>{{ $tuf('add_page') }}</span>
            </RouterLink>
          </li>
          <li>
            <RouterLink :to="{ name: 'website-edit' }" class="px-3 py-2 flex flex-row items-end leading-none transition duration-100 transition-colors text-prasset-gray-800 hover:text-prasset-green-400 focus:text-prasset-green-400">
              <i class="ri-edit-2-line mr-2" />
              <span>{{ $tuf('website_settings') }}</span>
            </RouterLink>
          </li>
          <li>
            <RouterLink :to="{ name: 'website-domain-edit' }" class="px-3 py-2 flex flex-row items-end leading-none transition duration-100 transition-colors text-prasset-gray-800 hover:text-prasset-green-400 focus:text-prasset-green-400">
              <i class="ri-global-line mr-2"></i>
              <span>{{ $tuf('domains') }}</span>
            </RouterLink>
          </li>
        </ul>
      </div>

    </aside>

    <main class="page-main">
      <div v-if="page" class="flex h-full">
        <div class="h-full overflow-auto w-96 flex-shrink-0 flex flex-col min-h-0 relative bg-gray-200 transition duration-100 transition-opacity" :class="{ 'opacity-0': preview === 'wide' }">
          <PageHeader class="bg-white border-r border-gray-200" :title="page.name" description="" :prefix="!showSidebarMenu ? 'Menu' : null" @click="showSidebarMenu = true">
            <div class="flex flex-row">
              <RouterLink :to="{ name: 'website-page-edit', params: { page_id: page.id } }" class="button button--clear button--clamp button--small text-gray-500 mb-1 border-b border-prasset-green-300"><i class="icon-edit" /></RouterLink>
              <button type="button" @click="confirmDelete(page)" class="button button--clear button--clamp button--small relative ri-delete-bin-line focus:outline-none text-gray-500" />
            </div>
          </PageHeader>

          <div class="p-5 h-full flex-grow-1 overflow-auto border-t border-gray-200">
            <div v-if="page.components && page.components.length > 0">
              <Draggable v-model="page.components" @input="onBeforeSort" @sort="onSortComponents">
                <ComponentItem
                  v-for="component in page.components"
                  :key="component.id"
                  :component="component"
                  @removed="onComponentRemoved"
                  class="mb-2"
                />
              </Draggable>
            </div>
          </div>

          <RouterLink :to="{ name: 'website-component-create', params: { page_id: page.id } }" class="text-center text-prasset-green-500 transition duration-100 transition-colors w-full p-5 bg-prasset-gray-100 hover:bg-prasset-green-400 hover:text-white focus:outline-none flex flex-row justify-center">
              <i class="ri-add-circle-line" /><span class="ml-2">{{ $tuf('add_new_component') }}</span>
          </RouterLink>
        </div>

        <BrowserFrame
          :tab="page.name"
          :url="`/${page.slug}`"
          @clickZoom="togglePreview"
          @clickRefresh="refreshPreview"
          :isLoading="previewLoading"
          class="w-full m-5 transition-margin duration-500 ease-in-out"
          :class="{ '-ml-92': preview === 'wide' }"
        >
          <template v-slot:actions>
            <a :href="page.previewUrl" target="_blank" class="button button--clear button--clamp button--small text-white" title="Openen in nieuw venster">
              <i class="ri-external-link-line" />
            </a>
          </template>
          <div class="absolute bottom-0 left-0 bg-prasset-red-600 text-xs text-white truncate rounded shadow px-2 py-1 m-2">{{ $t('preview') }}</div>
          <iframe name="previewIframe" :src="`${page.previewUrl}?idx=${idx}`" @load="onPreviewLoaded" class="w-full h-full min-h-96"></iframe>
        </BrowserFrame>
      </div>

      <RouterView /> <!-- for sidebar -->
    </main>
  </div>
</template>

<script>
import { toRefs, reactive, watch, computed, onMounted } from '@vue/composition-api';
import BrowserFrame from '@/modules/website/views/components/BrowserFrame';
import ComponentItem from '@/modules/website/views/components/ComponentItem';
import PageHeader from '@/modules/core/views/components/PageHeader';
import useWebsite from '@/compositions/useWebsite';
import Draggable from 'vuedraggable';
import EventBus from '@/eventbus';

export default {
  name: 'Pages',

  components: {
    PageHeader,
    BrowserFrame,
    ComponentItem,
    Draggable,
  },

  async beforeRouteUpdate(to, from, next) {
    const { loadPage } = useWebsite();

    if (from.params.page_id !== to.params.page_id) {
      await loadPage(to.params.project_id, to.params.page_id);
    }

    next();
  },

  setup(props, context) {
    const params = computed(() => context.root.$route.params);

    const {
      website, reloadPage, page, updatePageSorting,
      updateComponentSorting, deletePage, unloadPage,
    } = useWebsite();

    const state = reactive({
      idx: 1,
      preview: 'default',
      previewLoading: false,
      showSidebarMenu: true,
      watcherPaused: false,
    });

    function togglePreview() {
      if (state.preview === 'default') {
        state.preview = 'wide';
      } else {
        state.preview = 'default';
      }
    }

    function refreshPreview() {
      state.previewLoading = true;
      state.idx++;
    }

    function pauseWatcher() {
      state.watcherPaused = true;
    }

    function resumeWatcher() {
      state.watcherPaused = false;
    }

    function onBeforeSort() {
      pauseWatcher();
    }

    async function onSortComponents() {
      const ids = page.value.components.map(c => c.id);
      await updateComponentSorting(params.value.project_id, params.value.page_id, 'PAGE_SLOT', ids);
      refreshPreview();
      resumeWatcher();
    }

    async function onSortPages() {
      const ids = website.value.pages.map(c => c.id);
      await updatePageSorting(params.value.project_id, params.value.website_id, ids);
      refreshPreview();
      resumeWatcher();
    }

    async function onComponentRemoved() {
      await reloadPage(params.value.project_id, params.value.page_id);
    }

    function onPreviewLoaded() {
      state.previewLoading = false;
    }

    const confirmDelete = (page) => {
      EventBus.$emit('confirm:open', {
        title: 'Let op',
        main: `Weet je zeker dat je ${page.name} wilt verwijderen?`,
        confirmButton: 'Bevestigen',
        cancelButton: 'Annuleren',
        onConfirm() {
          deletePageFlow(page);
        },
      });
    };

    const deletePageFlow = (page) => {
      const page_id = page.id;
      const pages = website.value.pages;
      const pageIdx = pages.findIndex(page => page.id === page_id);
      let nextPageIdx = (pageIdx === 0 ? 1 : (pageIdx -1));

      context.root.$router.push({ name: 'website-page', params: { page_id: pages[nextPageIdx].id } });

      deletePage(params.value.project_id, page_id);
      unloadPage(page_id);
    };

    watch(page, v => {
      if (v !== null && !state.watcherPaused) {
        refreshPreview();
      }
    }, { deep: true });

    onMounted(async () => {
      const { loadWebsite, loadPage, loadComponentTypes } = useWebsite();

      await loadWebsite(params.value.project_id, params.value.website_id);

      loadComponentTypes(params.value.project_id);

      if (params.value.page_id) {
        await loadPage(params.value.project_id, params.value.page_id);
      }
    });

    return {
      ...toRefs(state),
      confirmDelete,
      onBeforeSort,
      onComponentRemoved,
      onPreviewLoaded,
      onSortComponents,
      onSortPages,
      page,
      refreshPreview,
      togglePreview,
      website,
    };
  },
};
</script>

<style lang="scss">
  @import '@/scss/crud';
</style>
