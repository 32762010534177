<template>
  <div class="page-header">
    <div>
      <h1>
        <span v-if="prefix" class="page-header__prefix" @click="$emit('click')">{{ prefix }}</span>
        <span v-if="prefix" class="page-header__separator"> / </span>
        {{ title }}
      </h1>
      <div v-if="description" class="text-gray-500 text-sm">{{ description }}</div>
    </div>
    <div class="page-header-actions">
      <div class="flex"><slot /></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    prefix: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
  .page-header {
    padding: 20px;
    font-size: 17px;
    min-height: 5.45rem;
    color: #1A282F;
    display: flex;
    justify-content: space-between;
    &-actions {
      @apply .ml-4;
      .button {
        @apply .ml-4;
      }
    }
    h1 {
      font-size: 21px;
      color: #097c7c;
      line-height: 1.25;
    }
    &__prefix {
      color: #ccc;
      font-weight: 300;
      cursor: pointer;

      &:hover {
        color: #1A282F;
      }
    }
    &__separator {
      position: relative;
      font-size: 16px;
      top: -2px;
    }
    &__total {
      font-size: 12px;
      color: #767682;
      line-height: 17.5px;
    }
  }
</style>
