<template>
  <div class="componentitem relative">
    <div v-if="loading" class="bg-white p-3 shadow rounded flex items-center overflow-hidden inset-0 bg-opacity-50 absolute">
      <LoadingIndicator class="text-gray-400 mx-auto mb-2" />
    </div>

    <div class="flex" :class="{ 'invisible': loading }">
      <RouterLink
        :to="{ name: 'website-component-edit', params: { component_id: component.id, type: 'component' } }"
        @mouseleave.native="fireCustomEvent('blur')"
        class="bg-white p-3 min-h-20 shadow rounded flex flex-row flex-shrink w-full overflow-hidden border border-transparent focus:outline-none focus:bg-gray-100 hover:bg-gray-100"
      >
        <span class="flex-1 flex flex-row  items-start overflow-hidden leading-none mt-3">
          <img :src="component.icon" class="block flex-shrink-0 mr-2" />
          <div>
            <span class="block truncate mb-2 max-w-56">{{ component.title }}</span>
            <span class="block truncate text-xs text-prasset-gray-400 max-w-56">{{ component.description }}</span>
          </div>
        </span>

        <div class="flex-shrink-0 flex flex-col  justify-between items-start">
          <button type="button" @click.prevent="fireCustomEvent('focus')" class="button button--clear button--clamp button--small text-gray-500"><i class="ri-window-fill"></i></button>
          <button type="button" @click.prevent="removeComponent" class="button button--clear button--clamp button--small text-gray-500"><i class="icon-delete" /></button>
        </div>
      </RouterLink>
    </div>
  </div>
</template>

<script>
import { toRefs, reactive } from '@vue/composition-api';
import EventBus from '@/eventbus';
import useWebsite from '@/compositions/useWebsite';
import LoadingIndicator from '@/components/LoadingIndicator';

export default {
  components: {
    LoadingIndicator,
  },

  props: {
    component: {
      type: Object,
      required: true,
    },
  },

  setup(props, { emit, root, listeners, attrs }) {
    const { project_id } = root.$route.params;
    const { deleteComponent } = useWebsite();

    const state = reactive({
      loading: false,
    });

    function removeComponent() {
      EventBus.$emit('confirm:open', {
        name: 'deleteComponent',
        title: 'Verwijderen',
        main: `Weet je zeker dat je dit onderdeel wil verwijderen?`,
        confirmButton: 'Ja, verwijderen',
        cancelButton: 'Annuleren',
        onConfirm: () => {
          emit('removing', props.component);
          state.loading = true;

          deleteComponent(project_id, props.component.id).then(async () => {
            await emitPromised('removed', props.component);
            state.loading = false;
            root.$notify({ type: 'success', title: 'Succes', text: 'Onderdeel is verwijderd' });
          }).catch(() => {
            emit('error', props.component);
            state.loading = false;
            root.$notify({ type: 'error', title: 'Fout', text: 'Onderdeel kan niet verwijderd worden' });
          });
        },
      });
    }

    async function emitPromised(method, ...params) {
      const listener = listeners[method] || attrs[method] || method;
      if (listener) {
        const res = await listener(...params);
        return res === undefined || res;
      }
      return false;
    }

    function fireCustomEvent(state) {
      const event = new CustomEvent(`prasset.suite.website.component.${state}`, {
        detail: {
          uuid: props.component.id,
        },
      });
      window.dispatchEvent(event);
    }

    return {
      ...toRefs(state),
      removeComponent,
      fireCustomEvent,
    };
  },
};
</script>

<style lang="scss">
  .componentitem {
    animation: componentitem-reveal 500ms;

    .router-link-exact-active:not(.button) {
      @apply .border .border-prasset-green-400;
    }
  }

  @keyframes componentitem-reveal {
    from { opacity: 0; }
    to { opacity: 1; }
  }
</style>
