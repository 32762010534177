<template>
  <div class="relative flex flex-col rounded bg-gray-100">
    <div class="rounded rounded-b-none bg-prasset-gray-800 items-end h-10 flex pl-4 pr-20">
      <div class="flex items-center h-full">
        <button type="button" class="bg-prasset-gray-600 rounded-full w-3 h-3 mr-1 focus:outline-none cursor-default" :class="{ 'hover:bg-red-700 focus:bg-red-700': $listeners.clickClose }" @click.prevent="$emit('clickClose')" title="Sluiten"></button>
        <button type="button" class="bg-prasset-gray-600 rounded-full w-3 h-3 mr-1 focus:outline-none cursor-default" :class="{ 'hover:bg-yellow-700 focus:bg-yellow-700': $listeners.clickMinimize }" @click.prevent="$emit('clickMinimize')" title="Minimaliseren"></button>
        <button type="button" class="bg-prasset-gray-600 rounded-full w-3 h-3 focus:outline-none cursor-default" :class="{ 'hover:bg-green-600 focus:bg-green-600 cursor-pointer': $listeners.clickZoom }" @click.prevent="$emit('clickZoom')" title="Uitvergroten of verkleinen"></button>
      </div>
      <div v-if="tab" class="bg-gray-100 ml-4 rounded rounded-b-none px-4 py-1 text-prasset-gray-500 text-sm min-w-32 max-w-64 truncate">
        {{ tab }}
      </div>
      <div v-if="$slots.actions" class="absolute top-0 right-0 mt-1 mr-4">
        <slot name="actions" />
      </div>
    </div>
    <div v-if="url" class="px-2 py-2 bg-gray-100 border-b border-gray-200 flex">
      <div class="mr-1" v-if="$listeners.clickRefresh">
        <button type="button" class="button button--clear button--clamp button--small text-prasset-gray-600" title="Vernieuwen" @click.prevent="$emit('clickRefresh')">
          <i class="ri-refresh-line" :class="{ 'rotate': isLoading }" />
        </button>
      </div>
      <div class="bg-gray-200 rounded-full py-1 px-2 text-sm text-prasset-gray-600 flex-1 flex items-center">
        {{ url }}
      </div>
    </div>
    <div class="relative flex-1 rounded rounded-t-none overflow-hidden border border-t-0 border-gray-200">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tab: {
      type: String,
      default: null,
    },

    url: {
      type: String,
      default: null,
    },

    isLoading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.rotate {
  animation: rotation 2s infinite linear;
}
@keyframes rotation {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(359deg);
	}
}
</style>
